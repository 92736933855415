import React, { useEffect, useState } from 'react'
import { Fade } from 'react-bootstrap'
import styles from './styles.module.css'

export default function OverlayComponent(props: any) {
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        if (props.visible) {
            setMounted(true)
        } else {
            setMounted(false)
        }
    }, [props.visible])

    if (!mounted) return null

    return (
        <Fade in={props.visible}>
            <div
                className="overlay-component-item"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    /* alignItems: 'center', */
                    backgroundColor: 'rgba(0,0,0,0.85)',
                    zIndex: 15,
                    paddingTop: '50px',
                    borderRadius: 10,
                }}>
                <OverlayAnimatedSVG />
            </div>
        </Fade>
    )
}

export const OverlayAnimatedSVG = ({ svgProps = {}, svgPathProps = {} }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="100px" height="100px" viewBox="0 0 880 881" preserveAspectRatio="xMidYMid meet" {...svgProps}>
            {/* Top Path */}
            <path
                className={[styles.svgPath, styles.topPath].join(' ')}
                d="M 410.5,-0.5 C 429.833,-0.5 449.167,-0.5 468.5,-0.5C 611.356,12.8182 723.856,77.8182 806,194.5C 849.158,260.142 873.658,332.142 879.5,410.5C 879.5,429.833 879.5,449.167 879.5,468.5C 877.523,491.996 873.856,515.329 868.5,538.5C 849.376,523.598 828.376,511.764 805.5,503C 751.794,483.792 696.46,472.125 639.5,468C 561.663,459.903 484.663,464.569 408.5,482C 290.8,511.529 181.467,559.529 80.5,626C 70.13,632.673 60.7967,640.506 52.5,649.5C 21.6704,593.012 4.00378,532.679 -0.5,468.5C -0.5,449.167 -0.5,429.833 -0.5,410.5C 12.8343,267.607 77.8343,155.107 194.5,73C 260.142,29.8416 332.142,5.34157 410.5,-0.5 Z"
                {...svgPathProps}
            />

            {/* Bottom Path */}
            <path
                className={[styles.svgPath, styles.bottomPath].join(' ')}
                d="M 868.5,538.5 C 837.177,665.61 764.844,761.444 651.5,826C 535.978,886.322 416.311,895.989 292.5,855C 186.911,816.088 106.911,747.588 52.5,649.5C 60.7967,640.506 70.13,632.673 80.5,626C 181.467,559.529 290.8,511.529 408.5,482C 484.663,464.569 561.663,459.903 639.5,468C 696.46,472.125 751.794,483.792 805.5,503C 828.376,511.764 849.376,523.598 868.5,538.5 Z"
                {...svgPathProps}
            />
        </svg>
    )
}
