import React, { useEffect, useState } from 'react'
import OverlayLoading from 'app/components/OverlayLoading'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
import { useSelector } from 'react-redux'
import { UserService } from 'app/services'
import { getBaseUrl, SERVICES } from 'app/services/BaseService'

/*
 *   Este fichero fue creado para Facu para embeber un powerbi en una pagina sin autorización
 *   Eliminar cuando no se requiera más
 */

const SalesUPLIndex = (props: any) => {
    const { user } = useSelector((state: any) => state.auth)
    const [embedData, setEmbedData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true)
        if (user.hasRole(UserService.roles().APP_PRECIOS)) {
            let baseUrl = getBaseUrl(SERVICES.CONNECTOR_BACKEND)
            if (baseUrl.endsWith('/')) baseUrl = baseUrl.slice(0, -1)
            let url = `${baseUrl}/azure/reports/getEmbedInfoTestReportSalesUPL?reportId=692d394e-3742-4131-bcb6-b6f29868e9da&datasetId=c7bf5cdb-5af9-4acd-a78b-f894f010b680`

            fetch(url)
                .then(res => res.json())
                .then(result => {
                    if (result != null) {
                        setEmbedData({
                            accessToken: result.token,
                            embedUrl: result.embedUrl,
                            reportId: result.reportId,
                        })

                        setLoading(false)
                    }
                })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <div style={{ position: 'relative', minHeight: 200, minWidth: '100%', width: '100%' }}>
                {embedData != null && (
                    <PowerBIEmbed
                        embedConfig={{
                            type: 'report', // Supported types: report, dashboard, tile, visual and qna
                            id: embedData.reportId,
                            embedUrl: embedData.embedUrl,
                            accessToken: embedData.accessToken,
                            tokenType: models.TokenType.Embed,
                            settings: {
                                panes: {
                                    filters: {
                                        expanded: false,
                                        visible: false,
                                    },
                                },
                                background: models.BackgroundType.Transparent,
                            },
                        }}
                        eventHandlers={
                            new Map([
                                ['loaded', (event: any, embeddedReport: any) => {}],
                                [
                                    'rendered',
                                    (event: any, embeddedReport: any) => {
                                        setLoading(false)
                                    },
                                ],
                                [
                                    'error',
                                    (event: any) => {
                                        /* console.log(event.detail); */
                                    },
                                ],
                                [
                                    'visualClicked',
                                    (event: any) => {
                                        console.log('Visual clicked', event)
                                    },
                                ],
                                [
                                    'dataSelected',
                                    (event: any, embeddedReport: any) => {
                                        console.log('Data selected', event)
                                    },
                                ],
                                [
                                    'selectionChanged',
                                    (event: any) => {
                                        console.log('Selection changed', event)
                                    },
                                ],
                            ])
                        }
                        cssClassName={'report-style-class'}
                        getEmbeddedComponent={embeddedReport => {}}
                    />
                )}
            </div>

            <OverlayLoading visible={loading} />
        </>
    )
}

export default SalesUPLIndex
